<template>
    <div>
    <section class="breadcrumb-outer text-center bg-orange">
        <div class="container">
                <h2>{{ $t('Header.PressService.Video') }}</h2>
        </div>
    </section>
    <div style="margin-top: 30px;" >
        <video-1></video-1>
    </div>
    </div>
</template>
<script>
import Video from '../components/Video3'
export default {
    name: "Videos",
    components:{
     'video-1' :   Video
    }
}
</script>
<style scoped>
@media(max-width: 400px){
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
.bg-orange{
    margin: 0;
    background-color: #3498DB !important;
    background-image: none;
}
section {
   padding: 40px 0 !important;
    position: relative;
}
.breadcrumb-outer h2{
    padding: 70px 0 15px !important;
}
@media(max-width: 400px){
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
</style>